.accordion {
	> .card {
	  &:not(:first-of-type) {
		.card-header:first-child {
		  border-radius: 0;
		}
  
		&:not(:last-of-type) {
		  border-bottom: 0;
		  border-radius: 0;
		}
	  }
  
	  &:first-of-type {
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	  }
  
	  &:last-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	  }
	}
  
	.card {
	  border-width: 0;
  
	  + .card {
		margin-top: 1px;
	  }
	}
  
	.card-header {
	  padding: 0;
	  background-color: transparent;
	  font-weight: 400;
  
	  a {
		display: block;
		padding: 12px;
		color: $default-color;
		position: relative;
		background-color:$primary-02;
		border:1px solid $border;
		border-radius: 5px;
		&[aria-expanded="true"] {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		&:hover, &:focus {
		  color: $primary;
		}
  
		&.collapsed {
		  color: $gray-700;
		  background-color: #fff;
		  border-bottom-color:$border ;
  
		  &:hover, &:focus {
			color: $gray-900;
			background-color: $primary-02;
		  }
		}
	  }
	}
  
	.card-body {
	  padding: 20px 20px;
	  background-color:$white;
	  transition: none;
	  border: 1px solid #ededf5;
	  border-top: 0px !important;
	  border-radius: 5px;
	}
	.collapse.show {
		.card-body {
			border-radius: 0px 0px 5px 5px;
		 }
	}
  }
  
  .card .card{
  	box-shadow:none !important;
  }
  .accordion > .card{
    border:0;
  }
  /* ###### 3.1 Accordion ###### */
  
  .accordion-indigo {
	.card {
	  border-color: $primary;
	}
  
	.card-header a {
	  color: $primary;
  
	  &:hover, &:focus {
		color: $primary;
	  }
  
	  &.collapsed {
		background-color: $primary;
		color: $white-8;
		font-weight: 400;
  
		&:hover, &:focus {
		  background-color: #452efa;
		  color: $white;
		}
	  }
	}
  }
  
  .accordion-blue {
	.card {
	  border-color: $primary;
	}
  
	.card-header a {
	  color: $primary;
  
	  &:hover, &:focus {
		color: $primary;
	  }
  
	  &.collapsed {
		background-color: $primary;
		color: $white-8;
		font-weight: 400;
  
		&:hover, &:focus {
		  background-color: #1a53ff;
		  color: $white;
		}
	  }
	}
  }
  
  .accordion-dark {
	.card {
	  background-color: #ebe2fc;
	}
  
	.card-header a {
	  color: #4a4a69;
  
	  &:hover, &:focus {
		color: $primary;
	  }
  
	  &.collapsed {
		background-color: #ffffff;
         color: #4d5875;
		font-weight: 400;
  
		&:hover, &:focus {
		  background-color: $primary-02;
                   color: $primary;
		}
	  }
	}
  }
  
  .accordion-gray {
	.card {
	  border-color: $gray-600;
	}
  
	.card-header a {
	  color: $gray-600;
  
	  &:hover, &:focus {
		color: $gray-600;
	  }
  
	  &.collapsed {
		background-color: $primary-02;
		color: $white-8;
		font-weight: 400;
  
		&:hover, &:focus {
		  background-color: #6a7a96;
		  color: $white;
		}
	  }
	}
  }
  
  /* ############### CUSTOM ELEMENT STYLES ############### */
  
  .main-accordion {
	.accordion-item {
	  background-color: $gray-100;
	  border: 1px solid #dee2ef;
	  position: relative;
	  transition: all 0.2s ease-in-out;
  
	  &:hover, &:focus {
		cursor: pointer;
		background-color: #f1f2f6;
		border-color: #bcc4de;
		z-index: 4;
	  }
  
	  &.active {
		background-color: $white;
		z-index: 5;
		border-color: #bcc4de;
  
		.accordion-title {
		  color: $primary;
		}
  
		.accordion-body {
		  display: block;
		}
	  }
  
	  + .accordion-item {
		margin-top: -1px;
	  }
	}
  
	.accordion-header {
	  display: flex;
	  align-items: center;
	  padding: 15px 20px;
	}
  
	.accordion-title {
	  font-weight: 700;
	  color: $default-color;
	  margin: 0;
	  transition: all 0.2s ease-in-out;
	}
  
	.accordion-body {
	  display: none;
	  padding: 0 20px 20px;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-accordion .accordion-item {
	  transition: none;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-accordion .accordion-title {
	  transition: none;
	}
  }
  
.panel-heading1 .panel-title1 a {
  display: block;
  color: #6a7882;
}

.panel-title1 {
  margin-bottom: 0;
}

.panel-heading1  {
  display: block;
  padding:12px;
  background: $primary-02 ;
  color:$primary;
}

.panel-title1 a {
  display: block;
  color: $white;
  padding: 15px;
  position: relative;
  font-weight: 400;
  font-size: 15px;
}

.panel-heading1.collapsed:before {
  content: "\e9af";
  position: absolute;
  font-family: 'feather';
  right: 10px;
  top: 6px;
  font-size: 20px;
  transition: all 0.5s;
  transform: scale(1);
}
.panel-group1 .panel-collapse.collapse.show:before{
  content: "\e994";
  position: absolute;
  font-family: 'feather';
  right: 10px;
  top: 6px;
  font-size: 20px;
  transition: all 0.5s;
  transform: scale(1);
  color:$primary;
}

.accor {
  &.bg-primary h4 a {
    color: $white;
  }

  div a {
    padding: 12px;
    display: block;
    border-bottom: 1px solid rgb(251, 251, 253);
    background: $primary-02;
    color: #4a4a69;
	border-radius: 5px;
	&[aria-expanded="true"] {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	}
  }
}
#accordion11 .panel-body {
    padding: 15px;
    border: 1px solid #ededf5;
    border-radius: 0px 0px 5px 5px;
}
.panel-heading2 {
    display: block;
    padding: 12px;
    background: #ffffff;
    color: $default-color;
}
.panel-heading2.collapsed:before {
    content: "\e92d";
    position: absolute;
    font-family: 'feather';
    right: 10px;
    top: 6px;
    font-size: 16px;
	line-height: 2.3;
    transition: all 0.5s;
    transform: scale(1);
}
.panel-group2 .panel-collapse.collapse.show:before {
    content: "\e930";
    position: absolute;
    font-family: 'feather';
    right: 10px;
    top: 6px;
    font-size: 16px;
	line-height: 2.3;
	transition: all 0.5s;
    transform: scale(1);
    color: $primary;
}
.accstyle {
	border-radius: 5px;
}
.collapse.show {
	.accstyle {
		border-radius: 0px 0px 5px 5px;
	}
}