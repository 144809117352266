
/* ###### 9.9 Width   ###### */

.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.wd-5p {
  width: 5%;
}

.wd-10p {
  width: 10%;
}

.wd-15p {
  width: 15%;
}

.wd-20p {
  width: 20%;
}

.wd-25p {
  width: 25%;
}

.wd-30p {
  width: 30%;
}

.wd-35p {
  width: 35%;
}

.wd-40p {
  width: 40%;
}

.wd-45p {
  width: 45%;
}

.wd-50p {
  width: 50%;
}

.wd-60p {
  width: 60%;
}

.wd-65p {
  width: 65%;
}

.wd-70p {
  width: 70%;
}

.wd-75p {
  width: 75%;
}

.wd-80p {
  width: 80%;
}

.wd-90p {
  width: 90%;
}

.wd-95p {
  width: 95%;
}

.wd-100p {
  width: 100%;
}

.wd-25 {
  width: 25px;
}

.wd-30 {
  width: 30px;
}

.wd-50 {
  width: 50px;
}

.wd-80 {
  width: 80px;
}

.wd-85 {
  width: 85px;
}

.wd-100 {
  width: 100px !important;
}

.wd-150 {
  width: 150px;
}

.wd-200 {
  width: 200px !important;
}

.wd-250 {
  width: 250px;
}

.wd-300 {
  width: 300px;
}

@media (min-width: 480px) {
  .wd-xs-5 {
    width: 5px;
  }

  .wd-xs-5p {
    width: 5%;
  }

  .wd-xs-10 {
    width: 10px;
  }

  .wd-xs-10p {
    width: 10%;
  }

  .wd-xs-15 {
    width: 15px;
  }

  .wd-xs-15p {
    width: 15%;
  }

  .wd-xs-20 {
    width: 20px;
  }

  .wd-xs-20p {
    width: 20%;
  }

  .wd-xs-25 {
    width: 25px;
  }

  .wd-xs-25p {
    width: 25%;
  }

  .wd-xs-30 {
    width: 30px;
  }

  .wd-xs-30p {
    width: 30%;
  }

  .wd-xs-35 {
    width: 35px;
  }

  .wd-xs-35p {
    width: 35%;
  }

  .wd-xs-40 {
    width: 40px;
  }

  .wd-xs-40p {
    width: 40%;
  }

  .wd-xs-45 {
    width: 45px;
  }

  .wd-xs-45p {
    width: 45%;
  }

  .wd-xs-50 {
    width: 50px;
  }

  .wd-xs-50p {
    width: 50%;
  }

  .wd-xs-55 {
    width: 55px;
  }

  .wd-xs-55p {
    width: 55%;
  }

  .wd-xs-60 {
    width: 60px;
  }

  .wd-xs-60p {
    width: 60%;
  }

  .wd-xs-65 {
    width: 65px;
  }

  .wd-xs-65p {
    width: 65%;
  }

  .wd-xs-70 {
    width: 70px;
  }

  .wd-xs-70p {
    width: 70%;
  }

  .wd-xs-75 {
    width: 75px;
  }

  .wd-xs-75p {
    width: 75%;
  }

  .wd-xs-80 {
    width: 80px;
  }

  .wd-xs-80p {
    width: 80%;
  }

  .wd-xs-85 {
    width: 85px;
  }

  .wd-xs-85p {
    width: 85%;
  }

  .wd-xs-90 {
    width: 90px;
  }

  .wd-xs-90p {
    width: 90%;
  }

  .wd-xs-95 {
    width: 95px;
  }

  .wd-xs-95p {
    width: 95%;
  }

  .wd-xs-100 {
    width: 100px;
  }

  .wd-xs-100p {
    width: 100%;
  }

  .wd-xs-150 {
    width: 150px;
  }

  .wd-xs-150p {
    width: 150%;
  }

  .wd-xs-200 {
    width: 200px;
  }

  .wd-xs-200p {
    width: 200%;
  }

  .wd-xs-250 {
    width: 250px;
  }

  .wd-xs-250p {
    width: 250%;
  }

  .wd-xs-300 {
    width: 300px;
  }

  .wd-xs-300p {
    width: 300%;
  }

  .wd-xs-350 {
    width: 350px;
  }

  .wd-xs-350p {
    width: 350%;
  }

  .wd-xs-400 {
    width: 400px;
  }

  .wd-xs-400p {
    width: 400%;
  }

  .wd-xs-450 {
    width: 450px;
  }

  .wd-xs-450p {
    width: 450%;
  }

  .wd-xs-500 {
    width: 500px;
  }

  .wd-xs-500p {
    width: 500%;
  }

  .wd-xs-550 {
    width: 550px;
  }

  .wd-xs-550p {
    width: 550%;
  }

  .wd-xs-600 {
    width: 600px;
  }

  .wd-xs-600p {
    width: 600%;
  }

  .wd-xs-650 {
    width: 650px;
  }

  .wd-xs-650p {
    width: 650%;
  }

  .wd-xs-700 {
    width: 700px;
  }

  .wd-xs-700p {
    width: 700%;
  }

  .wd-xs-750 {
    width: 750px;
  }

  .wd-xs-750p {
    width: 750%;
  }

  .wd-xs-800 {
    width: 800px;
  }

  .wd-xs-800p {
    width: 800%;
  }

  .wd-xs-850 {
    width: 850px;
  }

  .wd-xs-850p {
    width: 850%;
  }

  .wd-xs-900 {
    width: 900px;
  }

  .wd-xs-900p {
    width: 900%;
  }

  .wd-xs-950 {
    width: 950px;
  }

  .wd-xs-950p {
    width: 950%;
  }

  .wd-xs-1000 {
    width: 1000px;
  }

  .wd-xs-1000p {
    width: 1000%;
  }

  .wd-xs-auto {
    width: auto;
  }
}

@media (min-width: 576px) {
  .wd-sm-5 {
    width: 5px;
  }

  .wd-sm-5p {
    width: 5%;
  }

  .wd-sm-10 {
    width: 10px;
  }

  .wd-sm-10p {
    width: 10%;
  }

  .wd-sm-15 {
    width: 15px;
  }

  .wd-sm-15p {
    width: 15%;
  }

  .wd-sm-20 {
    width: 20px;
  }

  .wd-sm-20p {
    width: 20%;
  }

  .wd-sm-25 {
    width: 25px;
  }

  .wd-sm-25p {
    width: 25%;
  }

  .wd-sm-30 {
    width: 30px;
  }

  .wd-sm-30p {
    width: 30%;
  }

  .wd-sm-35 {
    width: 35px;
  }

  .wd-sm-35p {
    width: 35%;
  }

  .wd-sm-40 {
    width: 40px;
  }

  .wd-sm-40p {
    width: 40%;
  }

  .wd-sm-45 {
    width: 45px;
  }

  .wd-sm-45p {
    width: 45%;
  }

  .wd-sm-50 {
    width: 50px;
  }

  .wd-sm-50p {
    width: 50%;
  }

  .wd-sm-55 {
    width: 55px;
  }

  .wd-sm-55p {
    width: 55%;
  }

  .wd-sm-60 {
    width: 60px;
  }

  .wd-sm-60p {
    width: 60%;
  }

  .wd-sm-65 {
    width: 65px;
  }

  .wd-sm-65p {
    width: 65%;
  }

  .wd-sm-70 {
    width: 70px;
  }

  .wd-sm-70p {
    width: 70%;
  }

  .wd-sm-75 {
    width: 75px;
  }

  .wd-sm-75p {
    width: 75%;
  }

  .wd-sm-80 {
    width: 80px;
  }

  .wd-sm-80p {
    width: 80%;
  }

  .wd-sm-85 {
    width: 85px;
  }

  .wd-sm-85p {
    width: 85%;
  }

  .wd-sm-90 {
    width: 90px;
  }

  .wd-sm-90p {
    width: 90%;
  }

  .wd-sm-95 {
    width: 95px;
  }

  .wd-sm-95p {
    width: 95%;
  }

  .wd-sm-100 {
    width: 100px;
  }

  .wd-sm-100p {
    width: 100%;
  }

  .wd-sm-150 {
    width: 150px;
  }

  .wd-sm-150p {
    width: 150%;
  }

  .wd-sm-200 {
    width: 200px;
  }

  .wd-sm-200p {
    width: 200%;
  }

  .wd-sm-250 {
    width: 250px;
  }

  .wd-sm-250p {
    width: 250%;
  }

  .wd-sm-300 {
    width: 300px;
  }

  .wd-sm-300p {
    width: 300%;
  }

  .wd-sm-350 {
    width: 350px;
  }

  .wd-sm-350p {
    width: 350%;
  }

  .wd-sm-400 {
    width: 400px;
  }

  .wd-sm-400p {
    width: 400%;
  }

  .wd-sm-450 {
    width: 450px;
  }

  .wd-sm-450p {
    width: 450%;
  }

  .wd-sm-500 {
    width: 500px;
  }

  .wd-sm-500p {
    width: 500%;
  }

  .wd-sm-550 {
    width: 550px;
  }

  .wd-sm-550p {
    width: 550%;
  }

  .wd-sm-600 {
    width: 600px;
  }

  .wd-sm-600p {
    width: 600%;
  }

  .wd-sm-650 {
    width: 650px;
  }

  .wd-sm-650p {
    width: 650%;
  }

  .wd-sm-700 {
    width: 700px;
  }

  .wd-sm-700p {
    width: 700%;
  }

  .wd-sm-750 {
    width: 750px;
  }

  .wd-sm-750p {
    width: 750%;
  }

  .wd-sm-800 {
    width: 800px;
  }

  .wd-sm-800p {
    width: 800%;
  }

  .wd-sm-850 {
    width: 850px;
  }

  .wd-sm-850p {
    width: 850%;
  }

  .wd-sm-900 {
    width: 900px;
  }

  .wd-sm-900p {
    width: 900%;
  }

  .wd-sm-950 {
    width: 950px;
  }

  .wd-sm-950p {
    width: 950%;
  }

  .wd-sm-1000 {
    width: 1000px;
  }

  .wd-sm-1000p {
    width: 1000%;
  }

  .wd-sm-auto {
    width: auto;
  }
}

@media (min-width: 768px) {
  .wd-md-5 {
    width: 5px;
  }

  .wd-md-5p {
    width: 5%;
  }

  .wd-md-10 {
    width: 10px;
  }

  .wd-md-10p {
    width: 10%;
  }

  .wd-md-15 {
    width: 15px;
  }

  .wd-md-15p {
    width: 15%;
  }

  .wd-md-20 {
    width: 20px;
  }

  .wd-md-20p {
    width: 20%;
  }

  .wd-md-25 {
    width: 25px;
  }

  .wd-md-25p {
    width: 25%;
  }

  .wd-md-30 {
    width: 30px;
  }

  .wd-md-30p {
    width: 30%;
  }

  .wd-md-35 {
    width: 35px;
  }

  .wd-md-35p {
    width: 35%;
  }

  .wd-md-40 {
    width: 40px;
  }

  .wd-md-40p {
    width: 40%;
  }

  .wd-md-45 {
    width: 45px;
  }

  .wd-md-45p {
    width: 45%;
  }

  .wd-md-50 {
    width: 50px;
  }

  .wd-md-50p {
    width: 50%;
  }

  .wd-md-55 {
    width: 55px;
  }

  .wd-md-55p {
    width: 55%;
  }

  .wd-md-60 {
    width: 60px;
  }

  .wd-md-60p {
    width: 60%;
  }

  .wd-md-65 {
    width: 65px;
  }

  .wd-md-65p {
    width: 65%;
  }

  .wd-md-70 {
    width: 70px;
  }

  .wd-md-70p {
    width: 70%;
  }

  .wd-md-75 {
    width: 75px;
  }

  .wd-md-75p {
    width: 75%;
  }

  .wd-md-80 {
    width: 80px;
  }

  .wd-md-80p {
    width: 80%;
  }

  .wd-md-85 {
    width: 85px;
  }

  .wd-md-85p {
    width: 85%;
  }

  .wd-md-90 {
    width: 90px;
  }

  .wd-md-90p {
    width: 90%;
  }

  .wd-md-95 {
    width: 95px;
  }

  .wd-md-95p {
    width: 95%;
  }

  .wd-md-100 {
    width: 100px;
  }

  .wd-md-100p {
    width: 100%;
  }

  .wd-md-150 {
    width: 150px;
  }

  .wd-md-150p {
    width: 150%;
  }

  .wd-md-200 {
    width: 200px;
  }

  .wd-md-200p {
    width: 200%;
  }

  .wd-md-250 {
    width: 250px;
  }

  .wd-md-250p {
    width: 250%;
  }

  .wd-md-300 {
    width: 300px;
  }

  .wd-md-300p {
    width: 300%;
  }

  .wd-md-350 {
    width: 350px;
  }

  .wd-md-350p {
    width: 350%;
  }

  .wd-md-400 {
    width: 400px;
  }

  .wd-md-400p {
    width: 400%;
  }

  .wd-md-450 {
    width: 450px;
  }

  .wd-md-450p {
    width: 450%;
  }

  .wd-md-500 {
    width: 500px;
  }

  .wd-md-500p {
    width: 500%;
  }

  .wd-md-550 {
    width: 550px;
  }

  .wd-md-550p {
    width: 550%;
  }

  .wd-md-600 {
    width: 600px;
  }

  .wd-md-600p {
    width: 600%;
  }

  .wd-md-650 {
    width: 650px;
  }

  .wd-md-650p {
    width: 650%;
  }

  .wd-md-700 {
    width: 700px;
  }

  .wd-md-700p {
    width: 700%;
  }

  .wd-md-750 {
    width: 750px;
  }

  .wd-md-750p {
    width: 750%;
  }

  .wd-md-800 {
    width: 800px;
  }

  .wd-md-800p {
    width: 800%;
  }

  .wd-md-850 {
    width: 850px;
  }

  .wd-md-850p {
    width: 850%;
  }

  .wd-md-900 {
    width: 900px;
  }

  .wd-md-900p {
    width: 900%;
  }

  .wd-md-950 {
    width: 950px;
  }

  .wd-md-950p {
    width: 950%;
  }

  .wd-md-1000 {
    width: 1000px;
  }

  .wd-md-1000p {
    width: 1000%;
  }

  .wd-md-auto {
    width: auto;
  }

  .wd-md-120 {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .wd-lg-5 {
    width: 5px;
  }

  .wd-lg-5p {
    width: 5%;
  }

  .wd-lg-10 {
    width: 10px;
  }

  .wd-lg-10p {
    width: 10%;
  }

  .wd-lg-15 {
    width: 15px;
  }

  .wd-lg-15p {
    width: 15%;
  }

  .wd-lg-20 {
    width: 20px;
  }

  .wd-lg-20p {
    width: 20%;
  }

  .wd-lg-25 {
    width: 25px;
  }

  .wd-lg-25p {
    width: 25%;
  }

  .wd-lg-30 {
    width: 30px;
  }

  .wd-lg-30p {
    width: 30%;
  }

  .wd-lg-35 {
    width: 35px;
  }

  .wd-lg-35p {
    width: 35%;
  }

  .wd-lg-40 {
    width: 40px;
  }

  .wd-lg-40p {
    width: 40%;
  }

  .wd-lg-45 {
    width: 45px;
  }

  .wd-lg-45p {
    width: 45%;
  }

  .wd-lg-50 {
    width: 50px;
  }

  .wd-lg-50p {
    width: 50%;
  }

  .wd-lg-55 {
    width: 55px;
  }

  .wd-lg-55p {
    width: 55%;
  }

  .wd-lg-60 {
    width: 60px;
  }

  .wd-lg-60p {
    width: 60%;
  }

  .wd-lg-65 {
    width: 65px;
  }

  .wd-lg-65p {
    width: 65%;
  }

  .wd-lg-70 {
    width: 70px;
  }

  .wd-lg-70p {
    width: 70%;
  }

  .wd-lg-75 {
    width: 75px;
  }

  .wd-lg-75p {
    width: 75%;
  }

  .wd-lg-80 {
    width: 80px;
  }

  .wd-lg-80p {
    width: 80%;
  }

  .wd-lg-85 {
    width: 85px;
  }

  .wd-lg-85p {
    width: 85%;
  }

  .wd-lg-90 {
    width: 90px;
  }

  .wd-lg-90p {
    width: 90%;
  }

  .wd-lg-95 {
    width: 95px;
  }

  .wd-lg-95p {
    width: 95%;
  }

  .wd-lg-100 {
    width: 100px;
  }

  .wd-lg-100p {
    width: 100%;
  }

  .wd-lg-150 {
    width: 150px;
  }

  .wd-lg-150p {
    width: 150%;
  }

  .wd-lg-200 {
    width: 200px;
  }

  .wd-lg-200p {
    width: 200%;
  }

  .wd-lg-250 {
    width: 250px;
  }

  .wd-lg-250p {
    width: 250%;
  }

  .wd-lg-300 {
    width: 300px;
  }

  .wd-lg-300p {
    width: 300%;
  }

  .wd-lg-350 {
    width: 350px;
  }

  .wd-lg-350p {
    width: 350%;
  }

  .wd-lg-400 {
    width: 400px;
  }

  .wd-lg-400p {
    width: 400%;
  }

  .wd-lg-450 {
    width: 450px;
  }

  .wd-lg-450p {
    width: 450%;
  }

  .wd-lg-500 {
    width: 500px;
  }

  .wd-lg-500p {
    width: 500%;
  }

  .wd-lg-550 {
    width: 550px;
  }

  .wd-lg-550p {
    width: 550%;
  }

  .wd-lg-600 {
    width: 600px;
  }

  .wd-lg-600p {
    width: 600%;
  }

  .wd-lg-650 {
    width: 650px;
  }

  .wd-lg-650p {
    width: 650%;
  }

  .wd-lg-700 {
    width: 700px;
  }

  .wd-lg-700p {
    width: 700%;
  }

  .wd-lg-750 {
    width: 750px;
  }

  .wd-lg-750p {
    width: 750%;
  }

  .wd-lg-800 {
    width: 800px;
  }

  .wd-lg-800p {
    width: 800%;
  }

  .wd-lg-850 {
    width: 850px;
  }

  .wd-lg-850p {
    width: 850%;
  }

  .wd-lg-900 {
    width: 900px;
  }

  .wd-lg-900p {
    width: 900%;
  }

  .wd-lg-950 {
    width: 950px;
  }

  .wd-lg-950p {
    width: 950%;
  }

  .wd-lg-1000 {
    width: 1000px;
  }

  .wd-lg-1000p {
    width: 1000%;
  }

  .wd-lg-auto {
    width: auto;
  }
}

@media (min-width: 1200px) {
  .wd-xl-5 {
    width: 5px;
  }

  .wd-xl-5p {
    width: 5%;
  }

  .wd-xl-10 {
    width: 10px;
  }

  .wd-xl-10p {
    width: 10%;
  }

  .wd-xl-15 {
    width: 15px;
  }

  .wd-xl-15p {
    width: 15%;
  }

  .wd-xl-20 {
    width: 20px;
  }

  .wd-xl-20p {
    width: 20%;
  }

  .wd-xl-25 {
    width: 25px;
  }

  .wd-xl-25p {
    width: 25%;
  }

  .wd-xl-30 {
    width: 30px;
  }

  .wd-xl-30p {
    width: 30%;
  }

  .wd-xl-35 {
    width: 35px;
  }

  .wd-xl-35p {
    width: 35%;
  }

  .wd-xl-40 {
    width: 40px;
  }

  .wd-xl-40p {
    width: 40%;
  }

  .wd-xl-45 {
    width: 45px;
  }

  .wd-xl-45p {
    width: 45%;
  }

  .wd-xl-50 {
    width: 50px;
  }

  .wd-xl-50p {
    width: 50%;
  }

  .wd-xl-55 {
    width: 55px;
  }

  .wd-xl-55p {
    width: 55%;
  }

  .wd-xl-60 {
    width: 60px;
  }

  .wd-xl-60p {
    width: 60%;
  }

  .wd-xl-65 {
    width: 65px;
  }

  .wd-xl-65p {
    width: 65%;
  }

  .wd-xl-70 {
    width: 70px;
  }

  .wd-xl-70p {
    width: 70%;
  }

  .wd-xl-75 {
    width: 75px;
  }

  .wd-xl-75p {
    width: 75%;
  }

  .wd-xl-80 {
    width: 80px;
  }

  .wd-xl-80p {
    width: 80%;
  }

  .wd-xl-85 {
    width: 85px;
  }

  .wd-xl-85p {
    width: 85%;
  }

  .wd-xl-90 {
    width: 90px;
  }

  .wd-xl-90p {
    width: 90%;
  }

  .wd-xl-95 {
    width: 95px;
  }

  .wd-xl-95p {
    width: 95%;
  }

  .wd-xl-100 {
    width: 100px;
  }

  .wd-xl-100p {
    width: 100%;
  }

  .wd-xl-150 {
    width: 150px;
  }

  .wd-xl-150p {
    width: 150%;
  }

  .wd-xl-200 {
    width: 200px;
  }

  .wd-xl-200p {
    width: 200%;
  }

  .wd-xl-250 {
    width: 250px;
  }

  .wd-xl-250p {
    width: 250%;
  }

  .wd-xl-300 {
    width: 300px;
  }

  .wd-xl-300p {
    width: 300%;
  }

  .wd-xl-350 {
    width: 350px;
  }

  .wd-xl-350p {
    width: 350%;
  }

  .wd-xl-400 {
    width: 400px;
  }

  .wd-xl-400p {
    width: 400%;
  }

  .wd-xl-450 {
    width: 450px;
  }

  .wd-xl-450p {
    width: 450%;
  }

  .wd-xl-500 {
    width: 500px;
  }

  .wd-xl-500p {
    width: 500%;
  }

  .wd-xl-550 {
    width: 550px;
  }

  .wd-xl-550p {
    width: 550%;
  }

  .wd-xl-600 {
    width: 600px;
  }

  .wd-xl-600p {
    width: 600%;
  }

  .wd-xl-650 {
    width: 650px;
  }

  .wd-xl-650p {
    width: 650%;
  }

  .wd-xl-700 {
    width: 700px;
  }

  .wd-xl-700p {
    width: 700%;
  }

  .wd-xl-750 {
    width: 750px;
  }

  .wd-xl-750p {
    width: 750%;
  }

  .wd-xl-800 {
    width: 800px;
  }

  .wd-xl-800p {
    width: 800%;
  }

  .wd-xl-850 {
    width: 850px;
  }

  .wd-xl-850p {
    width: 850%;
  }

  .wd-xl-900 {
    width: 900px;
  }

  .wd-xl-900p {
    width: 900%;
  }

  .wd-xl-950 {
    width: 950px;
  }

  .wd-xl-950p {
    width: 950%;
  }

  .wd-xl-1000 {
    width: 1000px;
  }

  .wd-xl-1000p {
    width: 1000%;
  }

  .wd-xl-auto {
    width: auto;
    width: auto !important;
  }
}
.btn-w-xs {
    min-width: 90px;
}
.btn-w-sm {
    min-width: 110px;
}
.btn-w-lg {
    min-width: 150px;
}