/*
* bootstrap-tagsinput v0.8.0
* 
*/

.bootstrap-tagsinput {
  // background-color: #fff;
  // display: inline-block;
  // color: #777ab2;
  // vertical-align: middle;
  // border-radius: 0px;
  // width: 100%;
  // cursor: text;

  display: flex;
  flex-wrap: wrap;
  background: #fff;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #556270;
  padding: 5px 5px 0;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
    display: block;
    overflow: hidden;
    // border: none;
    // box-shadow: none;
    // outline: none;
    // background-color: transparent;
    // padding: 0 6px;
    // margin: 0;
    // width: auto;
    // max-width: inherit;
  }

  &.form-control input {
    &::-moz-placeholder {
      color: #777;
      opacity: 1;
    }

    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: #777;
    }
  }

  input:focus {
    border: none;
    box-shadow: none;
  }

  .badge {

    position: relative;
    background: #556270;
    display: block;
    max-width: 95%;
    /* width: 100%; */
    word-wrap: break-word;
    white-space: break-spaces;
    color: #fff;
    padding: 5px;
    border-radius: 2px;
    margin: 0 5px 5px 0;
    // margin-right: 2px;
    // color: white;
    // padding: 5px 12px 5px 12px !important;
    // border-radius: 0px;
    // margin-top: 8px;
    // border-radius: 3px;
    // line-height: inherit;

    [data-role="remove"] {
      margin-left: 8px;
      cursor: pointer;

      &:after {
        content: "×";
        padding: 0px 6px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        font-size: 15px;
        display: inline-block;
        text-align: center;
      }

      &:hover {
        &:after {
          background-color: rgba(0, 0, 0, 0.2);
        }

        &:active {
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
