.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #434f63;
  text-align: inherit;

  &:hover, &:focus {
    z-index: 0;
    color: $default-color;
    text-decoration: none;
    background-color: $bg-hover;
  }

  &:active {
    color: $default-color;
    background-color: $bg-active;
  }
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: $white;
  border: 1px solid rgb(231, 235, 243);

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.disabled, &:disabled {
    color: $gray-600;
    pointer-events: none;
    background-color: $white;
  }

  &.active {
    z-index: 2;
    color: $default-color;
    background-color: $bg-active !important;
    border-color: $border !important;
  }
}

.list-group-horizontal {
  flex-direction: row;

  .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top-right-radius: 0;
    }

    &:last-child {
      margin-right: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 0;
    }
  }
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;

    .list-group-item {
      margin-right: -1px;
      margin-bottom: 0;

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
      }

      &:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.list-group-flush {
  .list-group-item {
    border-right: 0;
    border-left: 0;
    border: 0 !important;
    border-radius: 0;

    &:last-child {
      margin-bottom: -1px;
    }
  }

  &:first-child .list-group-item:first-child {
    border-top: 0;
  }

  &:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.list-group-item-primary {
  color: $primary;
  background: $primary-02;

  &.list-group-item-action {
    &:hover, &:focus {
      color: $primary;
      background-color: $primary-02;
    }

    &.active {
      color: $white;
      background-color: $primary;
      border-color: $primary-border;
    }
  }
}

.list-group-item-secondary {
  color: $secondary;
  background-color: rgba(247, 79, 117, 0.13);

  &.list-group-item-action {
    &:hover, &:focus {
      color: #3f4654;
      background-color: #cacfdb;
    }

    &.active {
      color: $white;
      background-color: #3f4654;
      border-color: #3f4654;
    }
  }
}

.list-group-item-success {
  color: $success;
  background-color: rgba(26, 156, 134,0.13);

  &.list-group-item-action {
    &:hover, &:focus {
      color: #1f5c01;
      background-color: #b9e3a5;
    }

    &.active {
      color: $white;
      background-color: #1f5c01;
      border-color: #1f5c01;
    }
  }
}

.list-group-item-info {
  color: $info;
  background-color: rgba(25, 192, 192, 0.13);

  &.list-group-item-action {
    &:hover, &:focus {
      color: #0c5460;
      background-color: #abdde5;
    }

    &.active {
      color: $white;
      background-color: #0c5460;
      border-color: #0c5460;
    }
  }
}

.list-group-item-warning {
  color: $warning;
  background-color: rgba(255, 189, 90,0.13);

  &.list-group-item-action {
    &:hover, &:focus {
      color: #856404;
      background-color: #ffe8a1;
    }

    &.active {
      color: $white;
      background-color: #856404;
      border-color: #856404;
    }
  }
}

.list-group-item-danger {
  color: $danger;
  background-color: rgba(243, 67, 67,0.13);

  &.list-group-item-action {
    &:hover, &:focus {
      color: #721c24;
      background-color: #f1b0b7;
    }

    &.active {
      color: $white;
      background-color: #721c24;
      border-color: #721c24;
    }
  }
}

.list-group-item-light {
  color: #7f7f81;
  background-color: #fcfcfd;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #7f7f81;
      background-color: #ededf3;
    }

    &.active {
      color: $white;
      background-color: #7f7f81;
      border-color: #7f7f81;
    }
  }
}

.list-group-item-dark {
  color: #1f2533;
  background-color: #c8ccd3;

  &.list-group-item-action {
    &:hover, &:focus {
      color: #1f2533;
      background-color: #babfc8;
    }

    &.active {
      color: $white;
      background-color: #1f2533;
      border-color: #1f2533;
    }
  }
}
.listgroup-example ul {
  ul {
    list-style-type: square;
    margin: 10px 0 0 0;
  }

  list-style-type: none;

  li {
    padding: 5px 5px;
  }
}

.listgroup-example2 ul {
  ul {
    list-style-type: circle;
    margin: 10px 0 0 0;
  }

  list-style-type: none;

  li {
    padding: 5px 5px;
  }
}

.users-list-group {
  .btn-icon {
    i {
      line-height: 34px;
      line-height: 34px;
    }

    width: 34px;
    height: 34px;
  }

  .list-group-item {
    padding: 0.85rem 1.25rem;
  }
}

.list-style-1 {
    list-style: inherit;
    padding-left: inherit;
}

.list-style-1 ul {
    list-style: inherit;
    padding-left: 30px;
}

.list-style-1 li {
    line-height: 30px;
}

.list-style2 {
    list-style: decimal;
    padding-left: inherit;
}

.list-style2 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style2 li {
    line-height: 30px;
}

.list-style3 {
    list-style: circle !important;
    padding-left: inherit;
}

.list-style3 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style3 li {
    line-height: 30px;
}

.list-style4 {
    list-style: lower-alpha;
    padding-left: inherit;
}

.list-style4 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style4 li {
    line-height: 30px;
}

.list-style5 {
    list-style: square;
    padding-left: inherit;
}

.list-style5 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style5 li {
    line-height: 30px;
}

.list-style6 {
    list-style: lower-roman;
    padding-left: inherit;
}

.list-style6 ul {
    list-style: decimal;
    padding-left: 30px;
}

.list-style6 li {
    line-height: 30px;
}

.order-list li {
    line-height: 30px;
}
.paragraph-list {
  list-style: lower-roman;
  padding-left: inherit;
  list-style-type: inline;
  padding-left: 20px;
  line-height: 2;
}
