.notifit_confirm_accept{
    background-color: $primary !important;
	color:#fff !important;
	border-radius:4px;
}
#ui_notifIt.default{
    background-color: $primary;
	color: white;
}
#ui_notifIt.primary{
    background-color: $primary;
	color:#fff;
}