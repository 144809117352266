.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;

  .btn-close {
    position: absolute;
    color: inherit;
    font-size: 24px;
    background-image: none !important;
    padding: 7px !important;
    line-height: 1;
    top: 4px;
    z-index: 0;
  }
}

.alert-primary {
  color: $primary;
   background-color: $primary-02;
  border-color: $primary-02;

  hr {
    border-top-color: $primary;
  }

  .alert-link {
    color: $primary;
  }
}

.alert-secondary {
  color: $secondary;
  background-color: rgba(247, 79, 117, .2);
  border-color: rgba(247, 79, 117, .2);

  hr {
    border-top-color: $secondary;
  }

  .alert-link {
    color: #f72959;
  }
}

.alert-success {
  color: #1a9c86;
    background-color: rgba(26, 156, 134, .2);
    border-color: rgba(26, 156, 134, .2);

  hr {
    border-top-color: $success;
  }

  .alert-link {
    color: #059c83;
  }
}

.alert-info {
  color: #4ec2f0;
  background-color:rgba($info, .2);
  border-color:rgba($info, .2);

  hr {
    border-top-color: $info;
  }

  .alert-link {
    color: #1fb3ee;
  }
}

.alert-warning {
   color: #ffbd5a;
    background-color: rgba(255, 189, 90, .2);
    border-color: rgba(255, 189, 90, .2);

  hr {
    border-top-color: $warning;
  }

  .alert-link {
    color: #fca626;
  }
}

.alert-danger {
  color: $danger;
  background-color: rgba(243, 67, 67, .2);
  border-color: rgba(243, 67, 67, .2);

  hr {
    border-top-color: $danger;
  }

  .alert-link {
    color: #f11a1a;
  }
}

.alert-light {
  color: #7f7f81;
  background-color: #fdfdfe;
  border-color: #d8dae0;

  hr {
    border-top-color: #ededf3;
  }

  .alert-link {
    color: #666667;
  }
}

.alert-dark {
  color: #1f2533;
  background-color: #d8dae0;
  border-color: #c8ccd3;

  hr {
    border-top-color: #babfc8;
  }

  .alert-link {
    color: #0c0e13;
  }
}

.alert-default {
  color: #7f7f81;
  background-color: #fdfdfe;
  border-color: #d8dae0;

  hr {
    border-top-color: #babfc8;
  }

  .alert-link {
     color: #666667;
  }
}

/* ###### 3.2 Alerts ###### */

.alert {
  border-width: 1px;
  padding: 12px 15px;
  border-radius: 7px;

  .close {
    text-shadow: none;
    opacity: .5;
  }

  strong {
    font-weight: 600;
  }
}

[class*=alert-outline-] {
  border: 1px solid transparent;
}

.alert-outline-success {
  border-color: #1a9c86;
  color: $success;
}

.alert-outline-info {
  border-color: #4ec2f0;
  color: $info;
}

.alert-outline-warning {
    border-color: #ffbd5a;
    color: $warning;
}

.alert-outline-danger {
  border-color: #f34343;
    color: $danger;
}

[class*=alert-solid-] {
  border-width: 0;
  color: $white;
}

.alert-solid-success {
  background-color: $success;
}

.alert-solid-info {
  background-color: $info;
}

.alert-solid-warning {
  background-color: $warning;
}

.alert-solid-danger {
  background-color: $danger;
}